<template>
  <div id="form-section" v-on="$listeners">
    <!-- avatar -->

    <div id="avatar" :class="{ 'is-active': isActive }">
      <BaseIcon :name="icon" inherit-color />
    </div>

    <!-- ... -->

    <!-- info -->

    <div id="info">
      <div class="title-2">
        <slot name="title"></slot>
      </div>

      <div class="description">
        <slot name="description"></slot>
      </div>
    </div>

    <!-- ... -->

    <!-- form -->

    <div id="form">
      <slot></slot>
    </div>

    <!-- ... -->
  </div>
</template>

<script>
export default {
  name: "FormSection",

  props: {
    icon: {
      type: String,
      required: true,
    },

    isActive: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
#form-section {
  display: flex;
  align-items: flex-start;
  padding: 36px 0px 36px 16px;
  // border-bottom: 1px solid var(--divider-color);

  #avatar {
    height: 36px;
    width: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--icon-color);
    background-color: var(--component-bg-color-inferior);
    border-radius: 100%;

    &.is-active {
      color: $white;
      background-color: var(--secondary);
    }
  }

  #info {
    width: 320px;
    margin-left: 24px;
    margin-right: 48px;

    .description {
      @extend .text-sm;
      margin-top: 4px;
      color: var(--icon-color);
    }
  }

  #form {
    width: 560px;
  }
}
</style>
