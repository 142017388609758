<template>
  <div id="item-builder">
    <!-- header -->

    <div class="header">
      <div>
        <div class="title-1">
          <slot name="title"></slot>
        </div>
        <div class="description">
          <slot name="description"></slot>
        </div>
      </div>

      <div class="center">
        <slot name="header"></slot>
      </div>

      <q-space />

      <slot name="actions"></slot>

      <BaseCloseButton @click="close" />
    </div>

    <!-- ... -->

    <slot name="default"></slot>
  </div>
</template>

<script>
export default {
  name: "ItemBuilder",

  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
#item-builder {
  min-height: 100vh;
  background-color: var(--component-bg-color);

  .header {
    display: flex;
    align-items: center;
    padding: 16px 16px 8px;
    position: relative;

    .description {
      @extend .text-xs;
      color: var(--icon-color);
    }

    .center {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
